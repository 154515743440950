var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"calculator-widget"},[_c('div',{staticClass:"calculator-widget__info"},[_c('h3',{staticClass:"calculator-widget__title"},[_vm._v(_vm._s(_vm.config.labels.title))]),_c('p',{staticClass:"calculator-widget__text"},[_vm._v(_vm._s(_vm.config.labels.text))]),_c('p',{staticClass:"calculator-widget__small"},[_vm._v(" "+_vm._s(_vm.config.labels.footnote)),_c('br'),_vm._v(_vm._s(_vm.config.labels.fees)+" ")])]),_c('div',{staticClass:"calculator-widget__form"},[_c('div',{staticClass:"calculator-widget__select"},[_c('am-icon',{attrs:{"icon":"breafcase"}}),_c('am-select',{attrs:{"label":_vm.config.labels.company,"options":_vm.config.sp500},model:{value:(_vm.symbol1),callback:function ($$v) {_vm.symbol1=$$v},expression:"symbol1"}})],1),_c('div',{staticClass:"calculator-widget__select"},[_c('am-icon',{attrs:{"icon":"calendar_month"}}),_c('am-select',{attrs:{"label":_vm.config.labels.date_of_start,"options":_vm.config.period},model:{value:(_vm.investmentPeriod),callback:function ($$v) {_vm.investmentPeriod=$$v},expression:"investmentPeriod"}})],1),_c('div',{staticClass:"calculator-widget__select"},[_c('am-icon',{attrs:{"icon":"usd"}}),_c('am-select',{attrs:{"label":_vm.config.labels.amount,"options":_vm.config.money},model:{value:(_vm.investedMoney),callback:function ($$v) {_vm.investedMoney=$$v},expression:"investedMoney"}})],1),_c('div',{staticClass:"calculator-widget__calc"},[_c('am-button',{attrs:{"size":"large"},on:{"click":_vm.handleRecalculate}},[_vm._v(_vm._s(_vm.config.labels.calculate))])],1)]),_c('div',{staticClass:"calculator-widget__results",style:({ opacity: _vm.loading ? 0.5 : 1 })},[_c('div',{staticClass:"calculator-widget__result"},[_c('span',[_vm._v(_vm._s(_vm.formatter(_vm.result1))+" "),_c('am-icon',{style:({
            transform:
              _vm.result1 > _vm.investedMoney ? 'rotate(180deg)' : 'rotate(0deg)'
          }),attrs:{"icon":"arrow_drop_down","color":_vm.result1 > _vm.investedMoney ? 'positive' : 'negative'}})],1),_c('p',[_vm._v(_vm._s(_vm.config.labels.result1))])]),_c('div',{staticClass:"calculator-widget__result"},[_c('span',[_vm._v(_vm._s(_vm.formatter(_vm.result3))+" "),_c('am-icon',{style:({
            transform:
              _vm.inflation > _vm.investedMoney ? 'rotate(180deg)' : 'rotate(0deg)'
          }),attrs:{"icon":"arrow_drop_down","color":_vm.inflation > _vm.investedMoney ? 'positive' : 'negative'}})],1),_c('p',[_vm._v(" "+_vm._s(_vm.resultText2)+" ")])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }